import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthService, BaseRoutes } from '@nts/std';
import { environment } from 'src/environments/environment';

const title = environment ? environment.appTitle : 'Application Title';
const routes: Routes = [

  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        'loginconfiguration',
        () => import('./login-configuration/login-configuration.module').then(m => m.LoginConfigurationModule),
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'user',
        () => import('./user/user.module').then(m => m.UserModule),
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'profile',
        () => import('./user/user.module').then(m => m.UserModule),
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
    ]
  ),
  // #endregion rotte con autenticazione

  // #region rotte senza autenticazione
  {
    path: 'auth',
    loadComponent: () => import('./auth/components/login-empty-container/login-empty-container.component').then(mod => mod.LoginEmptyContainerComponent),
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  // Questo path è necessario per consentire a tutti i ms di fare redirect alla pagina di login senza effettivamente conoscerne il percorso, grazie alla funzione AuthService.login()
  // Con questo redirect tralascianto la / all'inizio di auth/login vengono mantenuti i query string correnti e passati alla nuova rotta
  // vedi: https://stackoverflow.com/a/52845530
  {
    path: AuthService.LOGIN_ALIAS_PATH,
    redirectTo: 'auth/login',
    pathMatch: 'full'
  }, {
    path: AuthService.CHANGE_PASSWORD_ALIAS_PATH,
    redirectTo: 'auth/reset-password',
    pathMatch: 'full'
  },
  {
    path: AuthService.LOGIN_CHOOSE_TENANT_ALIAS_PATH,
    redirectTo: 'auth/choose-tenant',
    pathMatch: 'full'
  },
  // #endregion rotte senza autenticazione

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', paramsInheritanceStrategy: 'always', preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

  public static readonly PrivacyPath = "/assets/static/informativa-privacy.html"
  public static readonly CookiePath = "/assets/static/informazioni-sui-cookies.html"

}
