import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NtsAppRootModule, NtsBootService, StdModule } from '@nts/std';
import { EnvironmentConfiguration, UNPROXIED_ENVIRONMENT, unproxiedEnvironmentFactory } from '@nts/std/src/lib/environments';
import { environment } from 'src/environments/environment';
import { environment as devEnvironment } from 'src/environments/environment.dev';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StdModule,
    HttpClientModule,
    // StdModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: EnvironmentConfiguration,
      useValue: { ...environment }
    },
    {
      provide: UNPROXIED_ENVIRONMENT,
      useFactory: unproxiedEnvironmentFactory(devEnvironment),
      deps: [EnvironmentConfiguration]
    },
    ...StdModule.getRootProviders(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule extends NtsAppRootModule {
  constructor(
    bootService: NtsBootService
  ) {
    super(bootService);
  }
}
